





















































import { defineComponent } from "@vue/composition-api"

import JeopardAiLoadingAnimation from "./components/JeopardAiLoadingAnimation.vue"

import useJeopardAiMain from "./use/useJeopardAiMain"

export default defineComponent({
  name: "JeopardAIMobile",
  components: {
    JeopardAiLoadingAnimation,
    JeopardAiLabel: () => import("./components/JeopardAiLabel.vue"),
    JeopardAiHint: () => import("./components/JeopardAiHint.vue"),
    JeopardAiCategoriesMobile: () =>
      import("./components/JeopardAiCategoriesMobile.vue"),
    JeopardAiQuestions: () => import("./components/JeopardAiQuestions.vue"),
    JeopardAiQuestionMobile: () =>
      import("./components/JeopardAiQuestionMobile.vue"),
    JeopardAiFinaleCategoryMobile: () =>
      import("./components/JeopardAiFinaleCategoryMobile.vue")
  },
  setup() {
    const {
      AnswersMode,
      finale,
      setCategory,
      question,
      working,
      questionId,
      questions,
      module,
      Module,
      state,
      category
    } = useJeopardAiMain()

    return {
      AnswersMode,
      finale,
      setCategory,
      question,
      working,
      questionId,
      questions,
      module,
      Module,
      state,
      category
    }
  }
})
