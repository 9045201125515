var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai-mobile" },
    [
      _c(
        "transition",
        { attrs: { name: "jeopardai-mobile__transition", mode: "out-in" } },
        [
          _vm.working
            ? _c("JeopardAiLoadingAnimation", {
                staticClass: "jeopardai-mobile__loading",
              })
            : _vm.module === _vm.Module.CATEGORIES
            ? _c("JeopardAiCategoriesMobile")
            : _vm.module === _vm.Module.QUESTIONS
            ? _c("JeopardAiQuestions", {
                staticClass: "jeopardai-mobile__questions mt-3 mb-3",
                attrs: {
                  questions: _vm.questions,
                  questionId: _vm.questionId,
                  category: _vm.category,
                },
                on: { category: _vm.setCategory },
              })
            : _vm.module === _vm.Module.ANSWERS && _vm.question
            ? _c("JeopardAiQuestionMobile", {
                key: _vm.question.id,
                staticClass: "jeopardai-mobile__answers",
                attrs: { question: _vm.question, state: _vm.state },
              })
            : _vm._e(),
          _vm.module === _vm.Module.FINALE &&
          _vm.finale &&
          _vm.finale.category != null &&
          _vm.finale.verified == null
            ? _c(
                "div",
                {
                  staticClass:
                    "h-full w-full rtb-flex flex-column items-center justify-center",
                },
                [
                  _c("JeopardAiLabel", [_vm._v(" FINAL ")]),
                  _c("JeopardAiHint", [
                    _vm._v(" We are verifying the final question "),
                  ]),
                ],
                1
              )
            : _vm.module === _vm.Module.FINALE &&
              _vm.finale &&
              _vm.finale.category != null
            ? _c("JeopardAiQuestionMobile", {
                key: _vm.Module.FINALE,
                staticClass: "pt-3 pb-3 jeopardai-mobile__answers",
                attrs: {
                  question: _vm.finale,
                  state: _vm.state,
                  mode: _vm.AnswersMode.FINALE,
                },
              })
            : _vm.module === _vm.Module.FINALE && _vm.state.categories
            ? _c("JeopardAiFinaleCategoryMobile", {
                attrs: { options: _vm.state.categories },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }